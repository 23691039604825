<template>
    <el-scrollbar class="main-container">
        <div class="container">
            <div class="first-title">京东CPS联盟和淘宝联盟的流程大致相同，分为以下步骤</div>
            <div class="line">
                1、注册并完成一系列的认证，注册地址:<a href="https://media.jd.com/" target="_blank">https://media.jd.com/</a>
            </div>
            <div class="line">2、通过后取得京东联盟ID，然后提交推广网站</div>
            <div class="line">3、网站通过后取得网站ID，然后去新建个网站推广位，就搞定了</div>

            <div class="second-title">下面详细讲下</div>
            <div class="line">
                a.先登陆<a href="https://media.jd.com/" target="_blank">https://media.jd.com/</a>，填写基本信息，注册好了联盟ID在 “顶部菜单---账户管理” 查看，京东钱包绑定和个人认证什么的都很简单，无非上传身份证之类的，就不多说了
            </div>
            <img :src="require('@/assets/img/pid/jd/1.png')" alt="">
            <div class="line">b1.聊天工具推广的小伙伴，新建一个聊天工具的推广位，在“我要推广----商品推广” ，随便选一个商品点推广，然后设置聊天推广位，点获取代码</div>
            <img :src="require('@/assets/img/pid/jd/2.png')" alt="">
            <div class="line">c. 把你的联盟ID和刚获取来的推广位ID填入淘鹊桥，这样聊天工具推广的小伙伴就可以转链拉</div>
            <img :src="require('@/assets/img/pid/jd/3.png')" alt="">
            <div class="line">这时已经可以转链了，试试吧</div>
            <img :src="require('@/assets/img/pid/jd/4.png')" alt="">

            <div class="second-title" style="margin-bottom: 40px">—————————————  分割线  淘鹊桥CMS网站推广者看过来  —————————————</div>
            <div class="line">b2. 进入顶部菜单“首页----CPS联盟”，右侧菜单----推广管理----网站管理，填加新网站</div>
            <img :src="require('@/assets/img/pid/jd/5.png')" alt="">
            <div class="line">c、添加后出现下载jd_root.txt，下载文件，并把这个文件上传到淘鹊桥推广中心，点提交（必须），然后切换到京东点完成验证。CMS的老同学是不是已经轻车熟路了。判断是否上传成功的依据是比如：你的域名abc.luckq.com，那加上abc.luckq.com/jd_root.txt，如果有字符串，则上传成功。</div>
            <img :src="require('@/assets/img/pid/jd/6.png')" alt="">
            <div class="line">
                d.大约一个工作日吧（时效不一定的），网站就验证通过了，然后我们去建立网站推广位，我要推广----商品推广----选一个商品点我要推广-----新建一个推广位（名字随便），获取代码就建好了，PS：这步可以省略，京粉APP转链要推广位，淘鹊桥CMS可以不要
            </div>
            <img :src="require('@/assets/img/pid/jd/7.png')" alt="">
            <div class="line">e.最后我们填上京东的网站ID和联盟ID，CMS就可以转链啦！</div>
            <img :src="require('@/assets/img/pid/jd/8.png')" alt="">
            <div class="line">f.用淘鹊桥转链的同学填在这里就可以了</div>
            <img :src="require('@/assets/img/pid/jd/9.png')" alt="">
            <div class="line">g.最后，转链后测试下订单，京东是不可以自推自买的，你的链接需要另一个京东ID下单才有订单，只要下单，无需付款就可以看到订单了，是不是很简单呢</div>
            <img :src="require('@/assets/img/pid/jd/10.png')" alt="">
        </div>
    </el-scrollbar>
</template>

<script>
    export default {

    }
</script>

<style scoped lang="scss">
    * {
        font-size: 16px;
        color: #222;
    }
    .main-container {
        position: relative;
        height: calc(100vh - 100px);
        display: flex;
        flex-direction: column;
        background: #fff;
        border-radius: 10px 10px 0 0;
        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .container {
        padding: 40px 40px 100px;
        .first-title {
            font-size: 24px;
            margin-bottom: 50px;
        }
        .line {
            margin-top: 20px;
        }
        .second-title {
            margin-top: 40px;
            font-size: 18px;
        }
        .red {
            color: #FE1E00;
        }
    }
    a {
        color: #5A50EF;
    }
    img {
        display: block;
        margin: 20px 0 0 50px;
        max-width: calc(100% - 50px);
    }
</style>